import React from 'react';
import { Row, Col } from 'reactstrap';
import NavBar from './components/NavBar';
import { GiStopSign }  from 'react-icons/gi';
import ModDownloader from './components/ModDownloader';


// Create a modal on hover for the Map with a quick preview and text.

function ServerSetup(props) {

  return (
      <div className="overallbg">

          <NavBar />
            <Row style={{background: 'white', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
            <h3>O:HD server setup instructions</h3>
            </Row>
            The official O:HD server setup documentation you can find here: <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2934087424" target="_new">Official O:HD Server setup guide</a><br /><br />
            <Row>
              <Col>
              This section will describe a subset of options that can be provided to the start up command 
              or through a servertravel RCON command.
              </Col>
            </Row>
            <Row>
              <Col md={2} xs={4}>BluforFaction</Col><Col md={10} xs={8}>Set blufor faction</Col>
            </Row>
            <Row>
              <Col md="2" xs={4}>OpforFaction</Col><Col md="10" xs={8}>Set opfor faction.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4} >MaxPlayers</Col><Col md="10" xs={8}>Set max number of servers slots.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>MinPlayers</Col><Col md="10" xs={8}>Set minimum number of players (use together with BotAutofill).</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>BluforNumBots</Col><Col md="10" xs={8}>Set number of Blufor bots.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>OpforNumBots</Col><Col md="10" xs={8}>Set number of Opfor bots.</Col>
            </Row>
            <Row>
              <Col md="2" xs={5}>BluforNumTickets</Col><Col md="10" xs={7}>Set number of tickets for BluFor faction.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={5}>OpforNumTickets</Col><Col md="10" xs={7}>Set number of tickets for OpFor faction.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>BotAutofill</Col><Col md="10" xs={8}>Will fill up the server until set MinPlayers. Does not take any options.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>DisableKitRestrictions</Col><Col md="10" xs={6}>Will disable any gear restrictions Does not take any options.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={5}>AutoAssignHuman</Col><Col md="10" xs={7}>Will force player to a team. 0 to OpFor, 1 for BluFor. (COOP)</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>Password</Col><Col md="10" xs={8}>Set a password for the server in a quoted string.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>-log</Col><Col md="10" xs={8}>Enables logging.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>-MapCycle</Col><Col md="10" xs={8}>Enables use of MapCyle file.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>-Port</Col><Col md="10" xs={8}>Game Port (UDP) on which O:HD is bound. Steam client port will be this port + 1 (OUTBOUND UDP).</Col>
            </Row>
            <Row>
              <Col md="2"  xs={4}>-QueryPort</Col><Col md="10" xs={8}>Port (UDP) on which steam is able to interogate the server for stats.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>-SteamServerName</Col><Col md="10" xs={6}>Sets the name of the O:HD server.</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>-Whitelist</Col><Col md="10" xs={6}>Lists steam64 ids of players granted access to the server in referenced file. (Default is Whitelist.cfg)</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>-AdminList</Col><Col md="10" xs={6}>Lists steam64 ids of players granted admin permissions to the server in referenced file. (Default is Admins.cfg)</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>-BanList</Col><Col md="10" xs={6}>Lists steam64 ids of players denied access to the server in referenced file. (Default is Bans.cfg)</Col>
            </Row>
            <Row>
              <Col md="2"  xs={6}>-SupportersOnly</Col><Col md="10" xs={6}>Only O:HD supporters are allowed access.</Col>
            </Row>
            <br />
            Example start up string:<br />
            <code className="codestyle">HarshDoorstopServer-Linux-Shipping HarshDoorstop khafji_p?BluforFaction=BP_HDFactionInfo_UnitedStates?OpforFaction=BP_HDFactionInfo_Insurgents?MaxPlayers=48?MinPlayers=24?BotAutofill?Password="ohd" -log -MapCycle -QueryPort=27005 -Port=7777 -SteamServerName="O:HD Server" -Whitelist=Whitelist.cfg </code>
            <br /><br />
            <GiStopSign size="50px" /> Before you go any further you should really start up the OHD server just once. This will create the framework of dirs and files to start with.
            <Row style={{background: 'black', color: 'white'}}> 
              <h2>Rcon</h2>
            </Row>
            <p>In order to use Rcon you need to edit the Game.ini and provide some additional options to it.</p>
            <p>Currently known RCON commands that work are:<br />
              servertravel [mapname] e.g <code className="codestyle">servertravel khafji_p</code><br />
              <code className="codestyle">servertravel risala?BluforFaction=BP_HDFactionInfo_TG_BluFor?OpforFaction=BP_HDFactionInfo_TG_OpFor?AutoAssignHuman=0</code><br />
            </p>
            The afocommunity created a nice servertravel string generator. <a href="https://hub.afocommunity.com/OHDTools/QueryGenerator" target="_new">https://hub.afocommunity.com/OHDTools/QueryGenerator</a>.
            <Row style={{background: 'black', color: 'white'}}>
              <h2>Mods</h2>
            </Row>
            Mod directories go in here: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Mods</code>. Each mod in its own directory.
            <br /><br />
            To download mods through the steamcmd you can use this syntax <br />
            <code>steamcmd.sh +login anonymous +workshop_download_item 736590 [mod-file-id-here] validate +quit</code><br /><br />
            Currently the mods are not placed in the correct directory yet. You need to move the mod directory from the download structure to the HarshDoorstop directory.<br />
            e.g <code> mv steamapps/workshop/content/736590/2934061853/SEAL_VBSS OHD_Server_PT_Linux/HarshDoorstop/Mods/</code><br /><br />
            I created a small wrapper for downloading and symlinking the mods downloaded from steam on Linux distributions. You can get the script here:
            <ModDownloader />
            <br />
            As for Windows environment, <code>LeeroyMajors#1026</code> has given his consent to publish his batch file to manage mods on windows. 
            You can find the batch file (in .txt) here: <a href="https://ohdservers.net/contrib/OHD_Server_Update_Workshop_Start.txt" target="_new">OHD_Server_Update_Workshop_Start</a> 

            <Row style={{background: 'black', color: 'white'}}>
              <h2>Game server configuration</h2>
            </Row>
            <p>If the files already contain something you should append, not override.</p>
            <Row>
              <Col>Game.ini, Path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/Game.ini</code></Col>
            </Row>
  
            [/Script/RCON.RCONServerSystem]<br />
            bEnabled=True<br />
            ListenPort=7779<br />
            Password=xxxxxyz <br />
            MaxActiveConnections=2 <br />
            MaxAuthAttempts=3 <br />
            <br />
            [/Script/HDMain.HDGameSession]<br />
            bSupportersOnlyWhitelist=false<br />
            <br />
            <Row>
            <Col>Engine.ini, Path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/Engine.ini</code></Col>
            </Row>
            [SystemSettings]<br />
            Game.FriendlyFireOverride=0<br />
            Game.AutoBalanceTeamsOverride=0<br />
            HD.AAS.ActiveRouteOverride=0<br />
            HD.CP.EnforceSmallerMinPlayersToCapture=-1<br />
            HD.CP.MinPlayersToCaptureOverride=-1<br />
            HD.Game.MinRespawnDelayOverride=0<br />
            HD.Game.DisableKitRestrictionsOverride=0<br />

            <br />
            <Row>
            <Col>MapCycle.cfg, Path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/MapCycle.cfg</code></Col>
            </Row>
            risala<br />
            khafji_p<br />
            lamdong<br />
            montecassino<br />
            argonne<br />
            <br />
            Maps in MapCycle.cfg can be extended with additional game mode and other url options/params on per map basis. See start up string on top for examples params options.
            <Row style={{background: 'black', color: 'white'}}>
              <h3>Default Factions</h3>
            </Row>
            BP_HDFactionInfo_Insurgents<br />
            BP_HDFactionInfo_PMC<br />
            BP_HDFactionInfo_Russians<br />
            BP_HDFactionInfo_TG_BluFor<br />
            BP_HDFactionInfo_TG_OpFor<br />
            BP_HDFactionInfo_UnitedStates<br />
            <br />
            <Row style={{background: 'black', color: 'white'}}> 
              <h3>Default maps</h3>
            </Row>
            risala<br />
            khafji_p<br />
            lamdong<br />
            montecassino<br />
            argonne<br />
            <br />
            <Row style={{background: 'black', color: 'white'}}>
              <h3>Systemd unit file</h3>
            </Row>
            <Row>
              <Col>systemd unit file. ohdserver.service, Path: <code className="codestyle">/usr/lib/systemd/system/ohdserver.service</code></Col></Row>
            [Unit]<br />
            Description=O:HD Server <br />
            After=network.target <br />
          
            
            [Service] <br />
            User=patrick <br />
            ExecStart=/home/patrick/OHD_Server_PT_Linux/HarshDoorstopServer.sh<br />
            Restart=always <br />
            
            [Install]<br />
            WantedBy=multi-user.target<br /><br />
            Make sure HarshDoorstopServer.sh is executable! <code>chmod +x HarshDoorstopServer.sh</code>
            <br />
            <Row style={{background: 'black', color: 'white'}}>
              <h3>Server management</h3>
            </Row>
            <b><u>Admins</u></b><br />
            In order to manage the server while in the game and kickplayers, changemap, etc you need to create a new file:<br />
            <Row>
              
              <Col>Server Admins config file, path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/Admins.cfg</code></Col></Row>
            This file needs to contain the admins steam64 ids. One per line.<br />
            <br />
            From console you should be able to run admin commands prefix with the word Admin:<br />
            e.g: <code className="codestyle">Admin servertravel risala</code><br /><br />
            Other commands known to work are:<br />
            status <br />
            kickid id# [reason] <br />
            banid id# [reason][duration]<br />
            forceteam id# teamid# <br />
            <br />
            <b><u>Whitelist</u></b>
            <Row>
              <Col>Whitelist config file, path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/Whitelist.cfg</code></Col>
            </Row>
              This file needs to contain steam64 ids of players granted access to the server. One id per line.
              <br />
              <br />
            <b><u>Bans</u></b>
            <Row>
              <Col>Bans file, path: <code className="codestyle">OHD_Server_PT_Linux/HarshDoorstop/Saved/Config/LinuxServer/Bans.cfg</code></Col>
            </Row>
            When a player gets banned this filename will be updated with steamID and timestamp.
            <br />
            <br />
            <b><u>steamcmd</u></b><br />
            <Row>
            <Col md="2">get steamcmd</Col>
            <Col md="10"><a href="https://developer.valvesoftware.com/wiki/SteamCMD#Running_SteamCMD" target="_new">https://developer.valvesoftware.com/wiki/SteamCMD#Running_SteamCMD</a></Col>
            </Row>
            <Row>
              <Col md="2">run steamcmd</Col>
              <Col md="10">
                <code>
                  #!/bin/bash<br />
                  export LD_LIBRARY_PATH=`pwd` <br />
                  ./steamcmd.sh +force_install_dir ohd01  +login anonymous +app_update 950900 validate +quit<br />
                </code>


              </Col>
            </Row>
            <br />
            <b><u>Nix OS</u></b><br />
            If you run NixOS to manage your steam server you can use the following shell.nix
            <Row>
              <Col>NixOS shell, path: <code className="codestyle"><a href="//ohdservers.net/contrib/shell.nix" target="_new">shell.nix</a></code></Col>
            </Row>
            <Row>
              <Col>NixOS env config file, path: <code className="codestyle"><a href="//ohdservers.net/contrib/config.nix" target="_new">.config/nixpkgs/config.nix</a></code></Col>
            </Row>
            



      </div>
  );
}


export default ServerSetup;